import { ApolloProvider } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import apollo from './apollo';
import App from './components/App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const env = process.env.REACT_APP_ENVIRONMENT ?? 'development';
const version = process.env.REACT_APP_VERSION ?? 'dev';

if (env !== 'development') {
  datadogRum.init({
    applicationId: 'b8f9fa0d-2b26-43a4-bc29-595881fe3887',
    clientToken: 'pub79477ba85977a277564ae16cdac9fff2',
    site: 'datadoghq.com',
    service: 'doug',
    env,
    version,
    sessionSampleRate: 5,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        environmentID: String(process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID),
        cacheFlags: true,
      }}
    >
      <ApolloProvider client={apollo}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
            <div id="portal-mount" />
            <ToastContainer hideProgressBar position="bottom-right" />
          </BrowserRouter>
        </HelmetProvider>
      </ApolloProvider>
    </FlagsmithProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
